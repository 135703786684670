import axios from 'axios';
import { useEffect, useState } from 'react';

const AdminSystemSettingsComponent = () => {
  const [settings, setSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('/api/admin/system-settings');
        setSettings(response.data);
      } catch (error) {
        setError('Fehler beim Laden der Systemeinstellungen.');
        console.error('Fehler beim Laden der Systemeinstellungen:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const validateSettings = () => {
    // Simple validation to ensure no field is empty
    for (let key in settings) {
      if (settings[key].trim() === '') {
        return `${key} darf nicht leer sein.`; // Error message in German: "<field> cannot be empty."
      }
    }
    return null;
  };

  const handleSaveSettings = async () => {
    // Validate settings before saving
    const validationError = validateSettings();
    if (validationError) {
      setError(validationError);
      return;
    }

    setIsSaving(true);
    setError('');
    setSuccessMessage('');

    try {
      await axios.put('/api/admin/system-settings', settings);
      setSuccessMessage('Systemeinstellungen erfolgreich gespeichert!');
    } catch (error) {
      setError('Fehler beim Speichern der Systemeinstellungen.');
      console.error('Fehler beim Speichern der Systemeinstellungen:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Systemeinstellungen
        </h2>

        {/* Loading State */}
        {isLoading ? (
          <p className="text-center text-gray-700">
            Lade Systemeinstellungen...
          </p>
        ) : (
          <>
            {/* Error Message */}
            {error && (
              <div className="mb-4 text-red-500 text-center font-semibold">
                {error}
              </div>
            )}

            {/* Success Message */}
            {successMessage && (
              <div className="mb-4 text-green-500 text-center font-semibold">
                {successMessage}
              </div>
            )}

            {/* Settings Form */}
            <div className="settings-form space-y-4">
              {Object.keys(settings).map(key => (
                <div key={key} className="setting-item">
                  <label
                    htmlFor={key}
                    className="block text-gray-700 text-sm font-bold mb-1"
                  >
                    {key}:
                  </label>
                  <input
                    type="text"
                    id={key}
                    name={key}
                    value={settings[key]}
                    onChange={handleInputChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              ))}
            </div>

            {/* Save Button */}
            <div className="mt-8 flex justify-center">
              <button
                onClick={handleSaveSettings}
                className={`bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                  isSaving
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-blue-700'
                }`}
                disabled={isSaving}
              >
                {isSaving ? 'Speichern...' : 'Einstellungen speichern'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminSystemSettingsComponent;
