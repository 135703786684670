import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('token');
  const { t } = useTranslation();

  const specialCharPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const invalidCharPattern = /[^A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  // ✅ Validate token on component mount
  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/validate-token?token=${resetToken}`
        );
        if (response.status === 200) {
          setIsTokenValid(true);
        }
      } catch (error) {
        setIsTokenValid(false);
      }
    };

    if (resetToken) {
      validateToken();
    }
  }, [resetToken]);

  // ✅ Password Strength Checker
  const checkPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/\d/.test(password)) strength += 1;
    if (specialCharPattern.test(password)) strength += 1;
    return strength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  // ✅ Submit Password Reset Request
  const handleResetPassword = async (event) => {
    event.preventDefault();
    setErrors([]);
    setMessage('');

    const validationErrors = [];

    // ✅ Password Validations
    if (password.length < 8) {
      validationErrors.push(t('passwordTooShort'));
    }
    if (!/[A-Z]/.test(password)) {
      validationErrors.push(t('passwordNoUppercase'));
    }
    if (!/\d/.test(password)) {
      validationErrors.push(t('passwordNoNumber'));
    }
    if (!specialCharPattern.test(password)) {
      validationErrors.push(t('passwordNoSpecialChar'));
    }
    if (invalidCharPattern.test(password)) {
      validationErrors.push(t('passwordInvalidSpecialChar'));
    }
    if (password !== confirmPassword) {
      validationErrors.push(t('passwordMismatch'));
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/reset-password`,
        { token: resetToken, newPassword: password },
        { headers: { 'Content-Type': 'application/json' } }
      );

      setMessage(t('passwordResetSuccess'));
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      setMessage(error.response?.data || t('resetError'));
    } finally {
      setIsLoading(false);
    }
  };

  // ✅ If Token is Invalid, Show Message
  if (isTokenValid === false) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold text-center mb-6">
            {t('invalidOrExpiredToken')}
          </h2>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            onClick={() => navigate('/forgot-password')}
          >
            {t('forgotPassword')}
          </button>
        </div>
      </div>
    );
  }

  return isTokenValid === null ? (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <p>{t('checkingToken')}</p>
    </div>
  ) : (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">{t('resetPassword')}</h2>
        <form onSubmit={handleResetPassword} className="space-y-4">
          <div className="relative">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              {t('newPassword')}
            </label>
            <input
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              placeholder={t('newPasswordPlaceholder')}
              required
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            />
            <span onClick={() => setShowPassword(!showPassword)} className="absolute right-3 top-9 cursor-pointer">
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </span>
          </div>

          <div>
            <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">
              {t('confirmPassword')}
            </label>
            <input
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t('confirmPasswordPlaceholder')}
              required
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            />
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-2 px-4 rounded bg-blue-500 text-white font-bold ${
                isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
              }`}
            >
              {isLoading ? t('resetting') : t('resetPassword')}
            </button>
          </div>

          {errors.length > 0 && <div className="text-red-600">{errors.join(', ')}</div>}
          {message && <div className="text-green-600">{message}</div>}
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
