import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../store/slices/userSlice';

const UserProfileComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user?.user);
  const [email, setEmail] = useState(user?.email || '');
  const [name, setName] = useState(user?.name || '');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Populate form with user data if it changes (e.g., from selecting a user in the list)
    if (user) {
      setEmail(user.email || '');
      setName(user.name || '');
    }
  }, [user]);

  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = 'Name is required.';
    }
    if (!email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email address is invalid.';
    }
    return newErrors;
  };

  const handleUpdateProfile = async event => {
    event.preventDefault();
    setErrors({}); // Reset errors before validation

    // Validate form fields
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setIsLoading(true); // Set loading state to true
    setSuccessMessage(''); // Clear any previous success messages

    try {
      // Dispatch the updateUser action with new information
      await dispatch(updateUser({ email, name })).unwrap(); // Assuming unwrap to handle errors properly
      setSuccessMessage('Profile updated successfully.');
    } catch (error) {
      setErrors({ form: 'Failed to update profile. Please try again later.' });
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Edit Profile</h2>
        <form onSubmit={handleUpdateProfile} className="space-y-4">
          {errors.form && (
            <div className="text-red-500 text-sm font-semibold">
              {errors.form}
            </div>
          )}

          <div className="input-group">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Enter your name"
              required
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.name ? 'border-red-500' : ''
              }`}
            />
            {errors.name && (
              <p className="text-red-500 text-xs italic">{errors.name}</p>
            )}
          </div>

          <div className="input-group">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.email ? 'border-red-500' : ''
              }`}
            />
            {errors.email && (
              <p className="text-red-500 text-xs italic">{errors.email}</p>
            )}
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className={`bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ${
                isLoading
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-blue-700'
              }`}
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Update Profile'}
            </button>
          </div>

          {successMessage && (
            <div className="mt-4 text-center text-sm font-semibold text-green-600">
              {successMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default UserProfileComponent;
