import axios from 'axios';

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, // Ensure cookies are sent with requests
  headers: {
    'Content-Type': 'application/json',
  },
});

// Response Interceptor (To handle errors globally)
axiosInstance.interceptors.response.use(
  response => {
    // If the response is successful, simply return it
    return response;
  },
  error => {
    // Handle 401 Unauthorized errors by redirecting to login page
    if (error.response?.status === 401) {
      console.error('Unauthorized request, redirecting to login...');
      // Redirect to login page
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
