import { createSlice } from '@reduxjs/toolkit';

// Initialer Zustand, ohne lokale Speicherung
const initialState = {
  user: null, // Enthält Benutzerinformationen wie Email, Rolle, etc.
  isAuthenticated: false, // Gibt an, ob der Benutzer authentifiziert ist
  role: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      // Setzt die Benutzerdaten im Redux-Zustand
      const { id, username, email, role, isEmailConfirmed } = action.payload;
      state.user = { id, username, email, role, isEmailConfirmed };
      state.isAuthenticated = true;
      state.role = role;
    },
    clearUser(state) {
      // Löscht die Benutzerdaten aus dem Redux-Zustand
      state.user = null;
      state.isAuthenticated = false;
      state.role = null;
    },
    updateUser(state, action) {
      // Aktualisiert die Benutzerdaten im Zustand (nützlich für Teilaktualisierungen)
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
      }
    },
  },
});

export const { setUser, clearUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
