import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import authenticationService from '../services/authenticationService'; // Import the authentication service
import { clearUser } from '../store/slices/userSlice';

const NavigationBarComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector(state => state.user?.role);

  const handleLogout = async () => {
    try {
      // Call the logout service which handles backend session invalidation
      await authenticationService.logout();
      console.log('Logout successful, clearing Redux store');

      // Clear the user from the Redux store
      dispatch(clearUser());

      // Redirect to the login page
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <nav className="bg-blue-600 text-white p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">My Dashboard</h1>
        <ul className="flex space-x-4 items-center">
          {/* Dashboard Link */}
          <li>
            <Link
              to="/pages/code-editor"
              className="hover:text-yellow-300 font-semibold"
            >
              Code Editor
            </Link>
          </li>

          {/* User Dropdown Menu */}
          <div className="relative group">
            <button className="hover:text-yellow-300 font-semibold">
              User Management
            </button>
            <ul className="absolute left-0 mt-1 hidden group-hover:block bg-white text-gray-800 p-2 rounded shadow-lg">
              <li>
                <Link
                  to="/user-profile"
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  User Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/user-management"
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  User Management
                </Link>
              </li>
            </ul>
          </div>

          {/* Admin Dropdown Menu - Visible Only to Admin Users */}
          {userRole === 'admin' && (
            <div className="relative group">
              <button className="hover:text-yellow-300 font-semibold">
                Admin
              </button>
              <ul className="absolute left-0 mt-1 hidden group-hover:block bg-white text-gray-800 p-2 rounded shadow-lg">
                <li>
                  <Link
                    to="/admin-dashboard"
                    className="block px-4 py-2 hover:bg-gray-200"
                  >
                    Admin Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/role-management"
                    className="block px-4 py-2 hover:bg-gray-200"
                  >
                    Role Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/system-settings"
                    className="block px-4 py-2 hover:bg-gray-200"
                  >
                    System Settings
                  </Link>
                </li>
              </ul>
            </div>
          )}

          {/* Chatbot Statistics Link */}
          <li>
            <Link
              to="/statistics"
              className="hover:text-yellow-300 font-semibold"
            >
              Chatbot Statistics
            </Link>
          </li>

          {/* FAQ Management */}
          <li>
            <Link
              to="/faq-management"
              className="hover:text-yellow-300 font-semibold"
            >
              FAQ Management
            </Link>
          </li>

          {/* Logout */}
          <li>
            <button
              onClick={handleLogout}
              className="hover:text-yellow-300 font-semibold"
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBarComponent;
