import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminUserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('/api/admin/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerinformationen:', error);
        setApiError('Fehler beim Laden der Benutzerinformationen.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleViewUserStats = userId => {
    navigate(`/admin/user/${userId}/statistics`);
  };

  const handleEditUser = userId => {
    navigate(`/admin/user-management/edit/${userId}`);
  };

  const handleDeleteUser = async userId => {
    if (window.confirm('Möchten Sie diesen Benutzer wirklich löschen?')) {
      try {
        await axios.delete(`/api/admin/user/${userId}`);
        setUsers(users.filter(user => user.id !== userId));
        alert('Benutzer erfolgreich gelöscht!');
      } catch (error) {
        console.error('Fehler beim Löschen des Benutzers:', error);
        setApiError('Fehler beim Löschen des Benutzers.');
      }
    }
  };

  const filteredUsers = users.filter(
    user =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Benutzerverwaltung
        </h2>

        {/* Search Bar */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Benutzername oder E-Mail suchen..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {/* Loading and Error States */}
        {isLoading ? (
          <p className="text-center text-gray-700">Lade Benutzerdaten...</p>
        ) : apiError ? (
          <p className="text-center text-red-500">{apiError}</p>
        ) : (
          <>
            {/* User List */}
            <div className="user-list space-y-4">
              {filteredUsers.length === 0 ? (
                <p className="text-center text-gray-700">
                  Keine Benutzer gefunden.
                </p>
              ) : (
                filteredUsers.map(user => (
                  <div
                    key={user.id}
                    className="user-card bg-gray-50 p-4 rounded-lg shadow-sm flex justify-between items-center"
                  >
                    <div>
                      <h3 className="text-lg font-bold text-gray-800">
                        {user.username}
                      </h3>
                      <p className="text-sm text-gray-600">
                        E-Mail: {user.email}
                      </p>
                    </div>
                    <div className="actions space-x-4">
                      <button
                        onClick={() => handleViewUserStats(user.id)}
                        className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline"
                      >
                        Statistiken anzeigen
                      </button>
                      <button
                        onClick={() => handleEditUser(user.id)}
                        className="text-white bg-green-500 hover:bg-green-700 font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline"
                      >
                        Bearbeiten
                      </button>
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="text-white bg-red-500 hover:bg-red-700 font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline"
                      >
                        Löschen
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminUserManagementPage;
