import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiTesterComponent from '../components/ApiTesterComponent';
import HeartbeatComponent from '../components/HeartbeatComponent';
import NavigationBarComponent from '../components/NavigationBarComponent';
import chatbotService from '../services/chatbotService';
import { selectChatbot, setChatbots } from '../store/slices/chatbotSlice';
import { clearUser } from '../store/slices/userSlice';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatbots = useSelector(state => state.chatbot.chatbots);
  const isAuthenticated = useSelector(state => state.user?.isAuthenticated);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      console.log('User is authenticated, attempting to load chatbots...');
      setIsLoading(true); // Start loading state

      loadChatbots()
        .then(() => {
          console.log('Chatbots loaded successfully');
        })
        .catch(error => {
          console.error('Error during loading chatbots:', error);
        })
        .finally(() => {
          setIsLoading(false); // End loading state regardless of success or failure
        });
    } else {
      console.warn('User is not authenticated, redirecting to login page...');
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const loadChatbots = async () => {
    try {
      const response = await chatbotService.getChatbotsByUserId();
      console.log('Received response from chatbotService:', response);
      dispatch(setChatbots(response.data));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          console.warn('Unauthorized access. Redirecting to login.');
          setError('Unauthorized access. Please login again.');
          dispatch(clearUser()); // Clear user in Redux and redirect to login
          navigate('/login');
        } else if (error.response.status === 500) {
          console.error('Server error while loading chatbots:', error);
          setError(
            'Server error while loading chatbots. Please try again later.'
          );
        } else {
          console.error('Failed to load chatbots:', error);
          setError('Failed to load chatbots. Please try again later.');
        }
      } else if (error.request) {
        console.error('No response received. Possible network issue.');
        setError(
          'Server not reachable. Please check your internet connection.'
        );
      } else {
        console.error(
          'Unexpected error occurred while loading chatbots:',
          error
        );
        setError('Unexpected error occurred. Please try again.');
      }
    }
  };

  const handleEditChatbot = chatbotId => {
    console.log(`Navigating to chatbot settings for chatbot ID: ${chatbotId}`);
    dispatch(selectChatbot(chatbotId));
    navigate(`/chatbot/${chatbotId}/settings`);
  };

  const handleAddChatbot = () => {
    console.log('Navigating to create new chatbot page...');
    navigate('/chatbot/new');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <HeartbeatComponent />
      <NavigationBarComponent />
      <div className="container mx-auto mt-8 p-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-bold text-gray-800">Your Chatbots</h2>
          <button
            onClick={handleAddChatbot}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded shadow-md transition-all duration-300 ease-in-out"
          >
            Add New Chatbot
          </button>
        </div>

        {isLoading && (
          <p className="text-center text-gray-700">Loading chatbots...</p>
        )}

        {error && <p className="text-center text-red-500 mb-4">{error}</p>}

        {!isLoading && !error && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {chatbots && chatbots.length === 0 ? (
              <div className="col-span-full text-center">
                <p className="text-gray-600 mb-4">
                  No chatbots found. Create a new chatbot to get started.
                </p>
                <button
                  onClick={handleAddChatbot}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded shadow-md transition-all duration-300 ease-in-out"
                >
                  Create Chatbot
                </button>
              </div>
            ) : (
              chatbots &&
              chatbots.map(chatbot => (
                <div
                  key={chatbot.id}
                  className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                >
                  <h3 className="text-xl font-semibold text-gray-800">
                    {chatbot.chatbotName}
                  </h3>
                  <p className="mt-2 text-gray-600">
                    <strong>Welcome Message:</strong> {chatbot.welcomeMessage}
                  </p>
                  <div className="mt-2 flex items-center">
                    <div
                      className="w-6 h-6 rounded-full mr-2"
                      style={{ backgroundColor: chatbot.themeColor }}
                    ></div>
                    <span className="text-gray-600">
                      <strong>Theme Color:</strong> {chatbot.themeColor}
                    </span>
                  </div>
                  <button
                    onClick={() => handleEditChatbot(chatbot.id)}
                    className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded shadow-md transition-all duration-300 ease-in-out"
                  >
                    Edit Chatbot
                  </button>
                </div>
              ))
            )}
          </div>
        )}
        {/* Integration of ApiTesterComponent */}
        <div className="mt-8">
          <ApiTesterComponent />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
