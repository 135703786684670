import prettier from 'prettier/standalone';
import parserBabel from 'prettier/parser-babel';
import { useState } from 'react';

function CodeEditor({ language, label, value, onChange }) {
    const [code, setCode] = useState(value || '');

    const handlePrettyPrint = async () => {
        console.log(`Starting pretty print for language: ${language}`);
        console.log('Current code before formatting:', code);

        try {
            const formattedCode = prettier.format(code, {
                parser: language === 'javascript' ? 'babel' : language,
                plugins: [parserBabel],
            });

            console.log('Formatted code:', formattedCode);
            setCode(formattedCode);
            onChange(formattedCode);
        } catch (error) {
            console.error('Error formatting code:', error);
            alert(`Error formatting code in ${language}: ${error.message}`);
        }
    };

    return (
        <div className="code-editor">
            <label>{label}</label>
            <button onClick={handlePrettyPrint} title="Pretty">
                Pretty
            </button>
            <textarea
                value={code}
                onChange={e => {
                    const newValue = e.target.value;
                    setCode(newValue);
                    onChange(newValue);
                }}
                style={{ width: '100%', height: '150px', fontFamily: 'monospace' }}
            />
        </div>
    );
}

export default CodeEditor;