import axiosInstance from './axiosInstance';

const API_BASE_URL = '/api/auth';

const login = async (email, password) => {
  console.log('Attempting login for email:', email);
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/login`,
      { email, password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true, // Send cookies with request
      }
    );

    console.log('Response received from server:', response);

    if (response.status === 200) {
      // Extrahiere Benutzerdaten aus der Antwort
      const { id, username, role, isEmailConfirmed } = response.data;

      // Logge die erhaltenen Benutzerdaten
      console.log('Extracted user data:', {
        id,
        username,
        role,
        isEmailConfirmed,
      });

      // Rückgabe der Benutzerdaten (Token wird im Cookie gespeichert)
      return { id, username, email, role, isEmailConfirmed };
    }
  } catch (error) {
    console.error('Login failed:', error);
    throw error; // Rethrow the error so it can be handled in the UI
  }
};

const signup = async userData => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/signup`,
      userData
    );
    console.log('Signup successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('Signup failed:', error);
    throw error;
  }
};

const logout = async () => {
  console.log('Attempting logout...');
  try {
    // Send a request to logout endpoint to invalidate session
    const response = await axiosInstance.post(`${API_BASE_URL}/logout`, null, {
      withCredentials: true,
    });
    if (response.status === 200) {
      console.log('Logout successful, user session invalidated');
    } else {
      console.warn(`Unexpected response during logout: ${response.status}`);
    }
  } catch (error) {
    if (error.response) {
      console.error(
        `Logout failed with status ${error.response.status}:`,
        error.response.data
      );
    } else if (error.request) {
      console.error('No response received during logout:', error.request);
    } else {
      console.error('Error setting up logout request:', error.message);
    }
  }
};

const confirmEmail = async token => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/confirm-email`, {
      token,
    });
    console.log('Email confirmation successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('Email confirmation failed:', error);
    throw error;
  }
};

export default {
  login,
  signup,
  logout,
  confirmEmail,
};
