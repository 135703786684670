import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminDashboardPage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    setIsLoading(true);
    setApiError('');
    try {
      const response = await axios.get('/api/admin/users');
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error('Fehler beim Laden der Benutzerinformationen:', error);
      setApiError(
        'Fehler beim Laden der Benutzerinformationen. Bitte versuchen Sie es später erneut.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = e => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = users.filter(
      user =>
        user.username.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };

  const handleViewUserStats = userId => {
    navigate(`/admin/user/${userId}/statistics`);
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 py-8">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-5xl">
        <h2 className="text-3xl font-bold mb-6 text-center">Admin Dashboard</h2>

        {/* Display error message */}
        {apiError && (
          <p className="text-center text-red-500 mb-4">{apiError}</p>
        )}

        {/* Search Bar */}
        <div className="mb-6 w-full flex justify-center">
          <input
            type="text"
            placeholder="Benutzername oder E-Mail suchen..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="shadow appearance-none border rounded w-full max-w-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {/* Loading Indicator */}
        {isLoading && (
          <p className="text-center text-gray-700">
            Benutzerdaten werden geladen...
          </p>
        )}

        {/* User List */}
        {!isLoading && !apiError && (
          <div className="user-list grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredUsers.length === 0 ? (
              <p className="text-center w-full">Keine Benutzer gefunden.</p>
            ) : (
              filteredUsers.map(user => (
                <div
                  key={user.id}
                  className="user-card bg-gray-50 p-6 rounded-lg shadow-md"
                >
                  <h3 className="text-lg font-bold mb-2">{user.username}</h3>
                  <p className="text-gray-600 mb-2">E-Mail: {user.email}</p>
                  <p className="text-sm text-gray-500 mb-4">
                    Rolle:{' '}
                    <span
                      className={`badge ${user.role === 'ADMIN' ? 'bg-blue-500 text-white' : 'bg-green-500 text-white'} px-2 py-1 rounded`}
                    >
                      {user.role}
                    </span>
                  </p>
                  <button
                    onClick={() => handleViewUserStats(user.id)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                  >
                    Statistiken anzeigen
                  </button>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboardPage;
