import axios from 'axios';
import { useState } from 'react';

function FaqManagement() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('jwtToken'); // JWT-Token aus dem localStorage holen
  console.log(token);

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    const faqData = {
      question,
      answer,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/questions`,
        faqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('FAQ erfolgreich gespeichert!');
      setQuestion('');
      setAnswer('');
    } catch (error) {
      setErrorMessage('Es gab ein Problem bei der Speicherung der FAQ.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <div className="flex-grow container mx-auto p-6">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-lg mx-auto"
        >
          <h2 className="text-2xl font-bold text-center mb-6">
            FAQ-Verwaltung
          </h2>

          {errorMessage && (
            <div className="text-red-600 text-center mb-4">{errorMessage}</div>
          )}

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="question"
            >
              Frage:
            </label>
            <input
              id="question"
              type="text"
              value={question}
              onChange={e => setQuestion(e.target.value)}
              placeholder="Frage eingeben"
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="answer"
            >
              Antwort:
            </label>
            <input
              id="answer"
              type="text"
              value={answer}
              onChange={e => setAnswer(e.target.value)}
              placeholder="Antwort eingeben"
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isLoading}
            >
              {isLoading ? 'Speichern...' : 'Speichern'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FaqManagement;
