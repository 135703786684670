import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, setUser } from '../store/slices/userSlice';

const useTokenRefresh = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.user.token);
  const expiresIn = useSelector(state => state.user.expiresIn);

  useEffect(() => {
    if (token && expiresIn) {
      const currentTime = new Date().getTime();
      const timeout = expiresIn - currentTime - 60000; // Refresh 1 minute before expiry

      if (timeout > 0) {
        console.log(`Setting up token refresh in ${timeout / 1000} seconds.`);

        const refreshTimeout = setTimeout(async () => {
          console.log('Attempting to refresh token...');

          try {
            // Request a new token from the backend
            const response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/api/auth/refresh-token`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`, // Use the existing token
                },
              }
            );

            const { token: newToken, expiresIn: newExpiresIn } = response.data;

            console.log('Token refreshed successfully:', newToken);
            console.log('New expiration time:', newExpiresIn);

            // Update Redux state with the new token and expiration time
            dispatch(
              setUser({
                token: newToken,
                expiresIn: newExpiresIn,
              })
            );

            // Update local storage with new token data
            localStorage.setItem('jwtToken', newToken);
            localStorage.setItem(
              'user',
              JSON.stringify({
                ...JSON.parse(localStorage.getItem('user')),
                expiresIn: newExpiresIn,
              })
            );
          } catch (error) {
            console.error('Failed to refresh token:', error);
            dispatch(clearUser());
          }
        }, timeout);

        // Clear the timer if the component unmounts or dependencies change
        return () => {
          console.log('Clearing existing refresh token timer.');
          clearTimeout(refreshTimeout);
        };
      }
    }
  }, [token, expiresIn, dispatch]);
};

export default useTokenRefresh;
