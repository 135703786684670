import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateChatbot } from '../store/slices/chatbotSlice';

const ChatbotSettingsPage = () => {
  const { chatbotId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatbots = useSelector(state => state.chatbot.chatbots);
  const chatbot = chatbots.find(bot => bot.id === chatbotId);

  const [welcomeMessage, setWelcomeMessage] = useState(
    chatbot?.welcomeMessage || ''
  );
  const [themeColor, setThemeColor] = useState(
    chatbot?.themeColor || '#ffffff'
  );
  const [chatbotName, setChatbotName] = useState(chatbot?.chatbotName || '');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (!chatbot) {
      setIsLoading(true);
      axios
        .get(`/api/chatbot-settings/user/1/chatbot/${chatbotId}`) // TODO: Replace '1' with actual user ID
        .then(response => {
          setWelcomeMessage(response.data.welcomeMessage);
          setThemeColor(response.data.themeColor);
          setChatbotName(response.data.chatbotName);
        })
        .catch(error => {
          console.error('Error loading chatbot settings:', error);
          setApiError(
            'Fehler beim Laden der Chatbot-Einstellungen. Bitte versuchen Sie es später erneut.'
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [chatbot, chatbotId]);

  const validateForm = () => {
    const newErrors = {};
    if (!chatbotName.trim()) {
      newErrors.chatbotName = 'Chatbot-Name wird benötigt.';
    }
    if (!/^#([0-9A-F]{3}){1,2}$/i.test(themeColor)) {
      newErrors.themeColor = 'Farbthema muss ein gültiger Hex-Code sein.';
    }
    if (!welcomeMessage.trim()) {
      newErrors.welcomeMessage = 'Willkommensnachricht wird benötigt.';
    }
    return newErrors;
  };

  const handleSave = () => {
    setErrors({});
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const updatedChatbot = {
      id: chatbotId,
      welcomeMessage,
      themeColor,
      chatbotName,
    };

    setIsLoading(true);
    axios
      .patch(
        `/api/chatbot-settings/user/1/chatbot/${chatbotId}`,
        updatedChatbot
      ) // TODO: Replace '1' with actual user ID
      .then(response => {
        dispatch(updateChatbot(response.data));
        setSuccessMessage('Einstellungen erfolgreich gespeichert.');
        setTimeout(() => navigate('/dashboard'), 2000);
      })
      .catch(error => {
        console.error('Error saving chatbot settings:', error);
        setApiError(
          'Fehler beim Speichern der Chatbot-Einstellungen. Bitte versuchen Sie es später erneut.'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-8">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Chatbot Einstellungen
        </h1>

        {/* Display Loading State */}
        {isLoading && (
          <p className="text-center text-gray-700">Lade Einstellungen...</p>
        )}

        {/* Display Error Message */}
        {apiError && (
          <p className="text-center text-red-500 mb-4">{apiError}</p>
        )}

        {!isLoading && (
          <form className="space-y-6">
            {/* Chatbot Name */}
            <div className="form-group">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="chatbotName"
              >
                Chatbot-Name:
              </label>
              <input
                type="text"
                id="chatbotName"
                value={chatbotName}
                onChange={e => setChatbotName(e.target.value)}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.chatbotName ? 'border-red-500' : ''
                }`}
              />
              {errors.chatbotName && (
                <p className="text-red-500 text-xs italic">
                  {errors.chatbotName}
                </p>
              )}
            </div>

            {/* Welcome Message */}
            <div className="form-group">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="welcomeMessage"
              >
                Willkommensnachricht:
              </label>
              <textarea
                id="welcomeMessage"
                value={welcomeMessage}
                onChange={e => setWelcomeMessage(e.target.value)}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.welcomeMessage ? 'border-red-500' : ''
                }`}
              />
              {errors.welcomeMessage && (
                <p className="text-red-500 text-xs italic">
                  {errors.welcomeMessage}
                </p>
              )}
            </div>

            {/* Theme Color */}
            <div className="form-group">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="themeColor"
              >
                Farbthema (Hex-Code):
              </label>
              <input
                type="text"
                id="themeColor"
                value={themeColor}
                onChange={e => setThemeColor(e.target.value)}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.themeColor ? 'border-red-500' : ''
                }`}
              />
              {errors.themeColor && (
                <p className="text-red-500 text-xs italic">
                  {errors.themeColor}
                </p>
              )}
            </div>

            {/* Save Button */}
            <div className="flex justify-center">
              <button
                type="button"
                onClick={handleSave}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isLoading}
              >
                {isLoading ? 'Speichern...' : 'Einstellungen speichern'}
              </button>
            </div>

            {/* Success Message */}
            {successMessage && (
              <div className="mt-4 text-center text-sm font-semibold text-green-600">
                {successMessage}
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default ChatbotSettingsPage;
