// store/slices/chatbotSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatbots: [],
  selectedChatbot: null,
};

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    setChatbots: (state, action) => {
      state.chatbots = action.payload;
    },
    selectChatbot: (state, action) => {
      state.selectedChatbot = action.payload;
    },
    addChatbot: (state, action) => {
      state.chatbots.push(action.payload);
    },
    updateChatbot: (state, action) => {
      const index = state.chatbots.findIndex(
        chatbot => chatbot.id === action.payload.id
      );
      if (index !== -1) {
        state.chatbots[index] = action.payload;
      }
    },
    deleteChatbot: (state, action) => {
      state.chatbots = state.chatbots.filter(
        chatbot => chatbot.id !== action.payload
      );
    },
  },
});

export const {
  setChatbots,
  selectChatbot,
  addChatbot,
  updateChatbot,
  deleteChatbot,
} = chatbotSlice.actions;

export default chatbotSlice.reducer;
