import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import authenticationService from './services/authenticationService'; // Importiere den Service
import { setUser } from './store/slices/userSlice';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isAuthenticated = useSelector(state => state.user?.isAuthenticated);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Monitor the authentication state to handle redirection
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/pages/dashboard');
    }
  }, [isAuthenticated, navigate]);

  // Handle Login
  const handleLogin = async event => {
    event.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    try {
      const userData = await authenticationService.login(email, password);
      dispatch(setUser(userData));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setErrorMessage(t('loginError')); // Falsche Anmeldedaten
        } else if (error.response.status === 500) {
          setErrorMessage(t('serverError')); // Allgemeiner Serverfehler
        }
      } else if (error.request) {
        setErrorMessage(t('serverNotReachable')); // Server nicht erreichbar
      } else {
        setErrorMessage(t('unknownError')); // Unbekannter Fehler
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Handle error messages
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center">{t('login')}</h2>
          {errorMessage && (
              <div className="text-red-500 text-center mb-4">{errorMessage}</div>
          )}
          <form onSubmit={handleLogin} className="space-y-4">
            <div className="input-group">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                {t('email')}
              </label>
              <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="input-group">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                {t('password')}
              </label>
              <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <button
                type="submit"
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isLoading}
            >
              {isLoading ? t('loading') : t('login')}
            </button>
          </form>
          <div className="mt-4 text-center">
            <button className="text-blue-500 hover:underline" onClick={() => navigate('/forgot-password')}>
              {t('forgotPassword')}
            </button>
          </div>
          <div className="mt-2 text-center">
            <span>{t('noAccount')}</span>{' '}
            <button className="text-blue-500 hover:underline" onClick={() => navigate('/signup')}>
              {t('signup')}
            </button>
          </div>
        </div>
      </div>
  );
};

export default LoginPage;
