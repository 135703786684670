// Signup.js
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

function Signup() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const specialCharPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const invalidCharPattern = /[^A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  const checkPasswordStrength = password => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/\d/.test(password)) strength += 1;
    if (specialCharPattern.test(password)) strength += 1;
    return strength;
  };

  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const handleSignup = async event => {
    event.preventDefault();
    const validationErrors = [];
    setErrors([]);
    setMessage('');

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      validationErrors.push(t('invalidEmail'));
    }

    if (username.length < 3) {
      validationErrors.push(t('invalidUsername'));
    }

    if (password.length < 8) {
      validationErrors.push(t('passwordTooShort'));
    }
    if (!/[A-Z]/.test(password)) {
      validationErrors.push(t('passwordNoUppercase'));
    }
    if (!/\d/.test(password)) {
      validationErrors.push(t('passwordNoNumber'));
    }
    if (!specialCharPattern.test(password)) {
      validationErrors.push(t('passwordNoSpecialChar'));
    }
    if (invalidCharPattern.test(password)) {
      validationErrors.push(t('passwordInvalidSpecialChar'));
    }

    if (password !== confirmPassword) {
      validationErrors.push(t('passwordMismatch'));
    }

    if (!termsAccepted) {
      validationErrors.push(t('termsNotAccepted'));
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/signup`,
        {
          username,
          email,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,  // ✅ This is required to send & receive cookies
        }
      );

      if (response.status === 200) {
        setMessage(t('signupSuccess'));
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    } catch (error) {
      setErrors([t('signupError')]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">{t('signup')}</h2>
        <form onSubmit={handleSignup} className="space-y-4">
          <div className="input-group">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              {t('username')}
            </label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={e => setUsername(e.target.value)}
              placeholder={t('usernamePlaceholder')}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="input-group">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              {t('email')}
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder={t('emailPlaceholder')}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="input-group relative">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              {t('password')}
            </label>
            <input
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              placeholder={t('passwordPlaceholder')}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-9 cursor-pointer"
            >
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </span>

            <div className="w-full h-2 mt-2 bg-gray-200 rounded">
              <div
                className={`h-full ${
                  passwordStrength === 1
                    ? 'bg-red-500'
                    : passwordStrength === 2
                      ? 'bg-yellow-500'
                      : passwordStrength === 3
                        ? 'bg-blue-500'
                        : 'bg-green-500'
                }`}
                style={{ width: `${(passwordStrength / 4) * 100}%` }}
              />
            </div>
          </div>

          <div className="input-group">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="confirmPassword"
            >
              {t('confirmPassword')}
            </label>
            <input
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              placeholder={t('confirmPasswordPlaceholder')}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="flex items-center">
            <input
              id="terms"
              type="checkbox"
              checked={termsAccepted}
              onChange={e => setTermsAccepted(e.target.checked)}
              className="mr-2 leading-tight"
            />
            <label htmlFor="terms" className="text-sm">
              {t('acceptTerms')}
            </label>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          >
            {isLoading ? t('loading') : t('signup')}
          </button>

          {errors.length > 0 && (
            <div className="mt-4 text-center text-sm font-semibold text-red-600">
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )}

          {message && (
            <div className="mt-4 text-center text-sm font-semibold text-green-600">
              {message}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Signup;
