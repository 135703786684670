import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AdminRoleManagementComponent = () => {
  const { userId } = useParams();
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setIsLoading(true);
        // Lade Rollen vom Backend
        const rolesResponse = await axios.get('/api/admin/roles');
        setRoles(rolesResponse.data);

        // Lade Benutzerrollen vom Backend
        const userRolesResponse = await axios.get(
          `/api/admin/user/${userId}/roles`
        );
        setSelectedRoles(userRolesResponse.data);
      } catch (err) {
        setError('Fehler beim Laden der Rollen oder Benutzerrollen.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRoles();
  }, [userId]);

  const handleRoleChange = role => {
    if (selectedRoles.includes(role)) {
      setSelectedRoles(selectedRoles.filter(r => r !== role));
    } else {
      setSelectedRoles([...selectedRoles, role]);
    }
  };

  const handleSaveRoles = async () => {
    if (window.confirm('Möchten Sie die Änderungen wirklich speichern?')) {
      setIsSaving(true);
      try {
        await axios.patch(`/api/admin/user/${userId}/roles`, {
          roles: selectedRoles,
        });
        alert('Rollen erfolgreich aktualisiert!');
        navigate('/admin/user-management');
      } catch (err) {
        setError('Fehler beim Aktualisieren der Benutzerrollen.');
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Rollenverwaltung für Benutzer ID: {userId}
        </h2>

        {/* Loading and Error Handling */}
        {isLoading ? (
          <p className="text-center text-gray-700">Lade Rollen...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : (
          <>
            <div className="role-list space-y-4">
              {roles.map(role => (
                <div key={role} className="role-item flex items-center">
                  <input
                    type="checkbox"
                    id={role}
                    checked={selectedRoles.includes(role)}
                    onChange={() => handleRoleChange(role)}
                    className="mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor={role} className="text-gray-700 font-semibold">
                    {role}
                  </label>
                </div>
              ))}
            </div>

            {/* Save Button */}
            <div className="mt-8 flex justify-center">
              <button
                onClick={handleSaveRoles}
                className={`bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                  isSaving
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-blue-700'
                }`}
                disabled={isSaving}
              >
                {isSaving ? 'Speichern...' : 'Rollen speichern'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminRoleManagementComponent;
