// ForgotPassword.js
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleForgotPassword = async event => {
    event.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/forgot-password`,
        {
          email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setMessage(t('resetEmailSent')); // Erfolgsmeldung unabhängig von der Existenz der E-Mail
    } catch (error) {
      setMessage(t('resetEmailError')); // Fehlermeldung bei Serverfehler
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">
          {t('forgotPassword')}
        </h2>
        <form onSubmit={handleForgotPassword} className="space-y-4">
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              {t('email')}
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder={t('emailPlaceholder')}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-2 px-4 rounded bg-blue-500 text-white font-bold ${
                isLoading
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-blue-600'
              }`}
            >
              {isLoading ? t('sending') : t('sendResetLink')}
            </button>
          </div>

          {message && (
            <div className="mt-4 text-center text-sm font-semibold text-green-600">
              {message}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
