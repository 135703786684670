import axios from 'axios';
import 'chart.js/auto'; // Chart.js for visualizations
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';

const ChatbotStatisticsComponent = () => {
  const { chatbotId } = useParams();
  const [totalRequests, setTotalRequests] = useState(0);
  const [totalDataTransferred, setTotalDataTransferred] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    if (startDate && endDate) {
      fetchChatbotStatistics();
    }
  }, [chatbotId, startDate, endDate]);

  const fetchChatbotStatistics = async () => {
    setLoading(true);
    setApiError('');
    try {
      const response = await axios.get(
        `/api/chatbot-usage-summary/user/1/chatbot/${chatbotId}?startDate=${startDate}&endDate=${endDate}` // TODO: Replace '1' with the actual user ID
      );
      setTotalRequests(response.data.totalRequests);
      setTotalDataTransferred(response.data.totalDataTransferred);
    } catch (error) {
      console.error('Fehler beim Laden der Chatbot-Statistiken:', error);
      setApiError('Fehler beim Laden der Chatbot-Statistiken.');
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = () => {
    if (!startDate || !endDate) {
      alert('Bitte sowohl Start- als auch Enddatum auswählen');
      return;
    }
    if (new Date(startDate) > new Date(endDate)) {
      alert('Das Enddatum darf nicht vor dem Startdatum liegen.');
      return;
    }
  };

  // Prepare data for Chart.js
  const data = {
    labels: ['Total Requests', 'Total Data Transferred (MB)'],
    datasets: [
      {
        label: 'Chatbot Statistiken',
        data: [totalRequests, totalDataTransferred / (1024 * 1024)], // Convert bytes to MB
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 159, 64, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 159, 64, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Chatbot Statistiken
        </h2>

        {/* Error Handling */}
        {apiError && (
          <p className="text-center text-red-500 mb-4">{apiError}</p>
        )}

        {/* Date Selection */}
        <div className="date-selection flex justify-between mb-6">
          <div className="flex flex-col w-1/2 mr-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="startDate"
            >
              Startdatum:
            </label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex flex-col w-1/2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="endDate"
            >
              Enddatum:
            </label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              min={startDate} // Restrict the end date to not be before start date
            />
          </div>
        </div>
        <button
          onClick={handleDateChange}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-6"
        >
          Statistiken anzeigen
        </button>

        {/* Loading Indicator */}
        {loading && (
          <p className="text-center text-gray-700">Lade Statistiken...</p>
        )}

        {/* Statistics Summary */}
        {!loading && !apiError && (
          <div className="statistics">
            <div className="text-center mb-4">
              <p className="text-lg text-gray-800 font-bold">
                Gesamte Anfragen: {totalRequests}
              </p>
              <p className="text-lg text-gray-800 font-bold">
                Übertragene Daten (MB):{' '}
                {(totalDataTransferred / (1024 * 1024)).toFixed(2)}
              </p>
            </div>

            {/* Chart for Data Visualization */}
            <div className="chart-container">
              <Line
                data={data}
                options={{ responsive: true, maintainAspectRatio: false }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatbotStatisticsComponent;
