import { Client as StompClient } from '@stomp/stompjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SockJS from 'sockjs-client';
import { clearUser } from '../store/slices/userSlice';

const HeartbeatComponent = () => {
  const [heartbeat, setHeartbeat] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Create SockJS instance
    const socket = new SockJS('https://localhost:8443/websocket');

    // Create Stomp client over SockJS
    const stompClient = new StompClient({
      webSocketFactory: () => socket, // Define SockJS as the WebSocket factory
      reconnectDelay: 5000, // Automatically attempt to reconnect every 5 seconds
      heartbeatIncoming: 4000, // Expect a heartbeat every 4 seconds from the server
      heartbeatOutgoing: 4000, // Send a heartbeat every 4 seconds to the server

      onConnect: frame => {
        console.log('Connected to WebSocket:', frame);
        // Subscribe to user-specific heartbeat topic
        stompClient.subscribe('/user/topic/heartbeat', message => {
          console.log('Received heartbeat message:', message.body);
          setHeartbeat(parseInt(message.body));
        });

        // Subscribe to user-specific logout topic
        stompClient.subscribe('/user/topic/logout', () => {
          console.warn('Session expired message received. Logging out user...');
          dispatch(clearUser()); // Clear user from Redux
          navigate('/login'); // Redirect user to login page
        });
      },

      onStompError: frame => {
        console.error('Broker reported error:', frame.headers['message']);
        console.error('Additional details:', frame.body);
      },

      onWebSocketError: error => {
        console.error('WebSocket error:', error);
      },
    });

    // Activate the Stomp client
    stompClient.activate();

    // Clean up the connection when the component unmounts
    return () => {
      if (stompClient) {
        stompClient.deactivate(() => {
          console.log('Disconnected from WebSocket');
        });
      }
    };
  }, [dispatch, navigate]);

  return (
    <div className="p-4">
      <p className="text-lg font-bold">Heartbeat Counter: {heartbeat}</p>
    </div>
  );
};

export default HeartbeatComponent;
