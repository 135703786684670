import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Verwende LocalStorage als Standard-Speicher
import chatbotReducer from './slices/chatbotSlice';
import userReducer from './slices/userSlice';

// Kombiniere alle Reducer
const rootReducer = combineReducers({
  chatbot: chatbotReducer,
  user: userReducer,
});

// Konfiguriere die Persistierung
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // Nur den user-Reducer speichern, damit der Zustand des Users nach einem Refresh erhalten bleibt
};

// Persistierten Reducer erstellen
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Konfiguriere den Store
const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
