// ApiTesterComponent.js
import { useState } from 'react';
import chatbotService from '../services/chatbotService';
import userService from '../services/userService';

const endpoints = [
  {
    name: 'Get User Settings',
    serviceMethod: () => chatbotService.getChatbotsByUserId(),
    endpointPath: '/api/chatbot-settings/user/{userId}',
  },
  {
    name: 'Update Welcome Message',
    serviceMethod: payload =>
      chatbotService.updateChatbot(payload.chatbotId, payload),
    defaultPayload:
      '{ "chatbotId": "123", "welcomeMessage": "Hello, welcome to our chatbot!" }',
    endpointPath: '/api/chatbot-settings/chatbot/{chatbotId}',
  },
  {
    name: 'Get Chatbot Usage',
    serviceMethod: payload =>
      chatbotService.getChatbotUsage(
        payload.chatbotId,
        payload.startDate,
        payload.endDate
      ),
    defaultPayload:
      '{ "chatbotId": "123", "startDate": "2023-01-01", "endDate": "2023-12-31" }',
    endpointPath: '/api/chatbot-usage/user/{userId}/chatbot/{chatbotId}',
  },
  {
    name: 'Get Total Usage By User',
    serviceMethod: payload =>
      chatbotService.getTotalUsageByUser(payload.startDate, payload.endDate),
    defaultPayload: '{ "startDate": "2023-01-01", "endDate": "2023-12-31" }',
    endpointPath: '/api/chatbot-usage/user/{userId}/total-usage',
  },
  {
    name: 'Get User Profile',
    serviceMethod: () => userService.getUserProfile(),
    endpointPath: '/api/user/profile',
  },
  {
    name: 'Update User Profile',
    serviceMethod: payload => userService.updateUserProfile(payload),
    defaultPayload:
      '{ "username": "newUsername", "email": "newEmail@example.com" }',
    endpointPath: '/api/user/profile',
  },
  {
    name: 'Create Chatbot',
    serviceMethod: payload => chatbotService.createChatbot(payload),
    defaultPayload:
      '{ "chatbotId": "newChatbotId", "welcomeMessage": "Hello!", "themeColor": "#FFFFFF", "maxRequestsPerMonth": 100, "maxDataTransferPerMonth": 1000, "allowMediaMessages": true, "language": "en", "chatbotName": "My Chatbot" }',
    endpointPath: '/api/chatbot-settings/save',
  },
  {
    name: 'Delete Chatbot',
    serviceMethod: payload => chatbotService.deleteChatbot(payload.chatbotId),
    defaultPayload: '{ "chatbotId": "123" }',
    endpointPath: '/api/chatbot-settings/chatbot/{chatbotId}',
  },
  {
    name: 'Get Chatbot By ID',
    serviceMethod: payload => chatbotService.getChatbotById(payload.chatbotId),
    defaultPayload: '{ "chatbotId": "123" }',
    endpointPath: '/api/chatbot-settings/chatbot/{chatbotId}',
  },
  {
    name: 'Update Chatbot Settings',
    serviceMethod: payload =>
      chatbotService.updateChatbot(payload.chatbotId, payload),
    defaultPayload:
      '{ "chatbotId": "123", "welcomeMessage": "Updated Welcome Message", "themeColor": "#000000", "maxRequestsPerMonth": 200, "maxDataTransferPerMonth": 5000, "allowMediaMessages": false, "language": "de", "chatbotName": "Updated Chatbot Name" }',
    endpointPath: '/api/chatbot-settings/chatbot/{chatbotId}',
  },
  {
    name: 'Save Chatbot Usage',
    serviceMethod: payload => chatbotService.saveUsage(payload),
    defaultPayload:
      '{ "userId": "123", "chatbotId": "123", "requestCount": 1, "dataTransferredInBytes": 1024, "requestType": "TEXT", "additionalMetadata": "Sample metadata" }',
    endpointPath: '/api/chatbot-usage/save',
  },
  {
    name: 'Get Usage Summary',
    serviceMethod: payload =>
      chatbotService.getUsageSummary(
        payload.userId,
        payload.chatbotId,
        payload.startDate,
        payload.endDate
      ),
    defaultPayload:
      '{ "userId": "123", "chatbotId": "123", "startDate": "2023-01-01", "endDate": "2023-12-31" }',
    endpointPath:
      '/api/chatbot-usage/user/{userId}/chatbot/{chatbotId}/summary',
  },
];

const ApiTesterComponent = () => {
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  const [requestPayload, setRequestPayload] = useState('');
  const [response, setResponse] = useState('');
  const [userId, setUserId] = useState('');
  const [chatbotId, setChatbotId] = useState('');

  const handleEndpointChange = event => {
    const endpoint = endpoints.find(ep => ep.name === event.target.value);
    setSelectedEndpoint(endpoint);
    setRequestPayload(endpoint.defaultPayload || '');
    setResponse(''); // Reset response when a new endpoint is selected
  };

  const handleSendRequest = async () => {
    if (!selectedEndpoint) return;
    try {
      const payload = requestPayload ? JSON.parse(requestPayload) : {};

      // Adding userId and chatbotId if they exist
      if (userId) payload.userId = userId;
      if (chatbotId) payload.chatbotId = chatbotId;

      const response = await selectedEndpoint.serviceMethod(payload);
      setResponse(JSON.stringify(response.data, null, 2));
    } catch (error) {
      setResponse(
        JSON.stringify(error.response?.data || error.message, null, 2)
      );
    }
  };

  return (
    <div className="api-tester">
      <h2>API Tester</h2>
      <div className="dropdown">
        <label htmlFor="endpoint-select">Wähle einen Endpoint:</label>
        <select id="endpoint-select" onChange={handleEndpointChange}>
          <option value="">-- Wähle einen Endpoint --</option>
          {endpoints.map(endpoint => (
            <option key={endpoint.name} value={endpoint.name}>
              {endpoint.name}
            </option>
          ))}
        </select>
      </div>

      {selectedEndpoint && (
        <div className="request-section">
          <div className="id-inputs">
            <label>
              User ID:
              <input
                type="text"
                value={userId}
                onChange={e => setUserId(e.target.value)}
                placeholder="Optional User ID"
              />
            </label>
            <label>
              Chatbot ID:
              <input
                type="text"
                value={chatbotId}
                onChange={e => setChatbotId(e.target.value)}
                placeholder="Optional Chatbot ID"
              />
            </label>
          </div>
          <h3>Request Payload</h3>
          <textarea
            rows="8"
            cols="50"
            value={requestPayload}
            onChange={e => setRequestPayload(e.target.value)}
          />
          <p>
            <strong>Request Path:</strong>{' '}
            {selectedEndpoint.endpointPath
              .replace('{userId}', userId || '<userId>')
              .replace('{chatbotId}', chatbotId || '<chatbotId>')}
          </p>
          <button onClick={handleSendRequest}>Absenden</button>
        </div>
      )}

      {response && (
        <div className="response-section">
          <h3>Response</h3>
          <textarea rows="8" cols="50" value={response} readOnly />
        </div>
      )}
    </div>
  );
};

export default ApiTesterComponent;
