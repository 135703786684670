import { useState } from 'react';
import CodeEditor from './CodeEditor';

function CodeEditorDashboard() {
  const [html, setHtml] = useState('<div>Hello World</div>');
  const [css, setCss] = useState('div { color: red; }');
  const [js, setJs] = useState('console.log("Hello, World!");');
  const [srcDoc, setSrcDoc] = useState('');

  const runCode = () => {
    console.log('Running code preview');
    try {
      setSrcDoc(`
                <html>
                    <head><style>${css}</style></head>
                    <body>
                        ${html}
                        <script>${js}</script>
                    </body>
                </html>
            `);
      console.log('Code successfully set in preview iframe');
    } catch (error) {
      console.error('Fehler beim Erstellen der Code-Vorschau:', error);
      alert(`Fehler beim Erstellen der Code-Vorschau: ${error.message}`);
    }
  };

  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr', gap: '1rem' }}>
      <h1>Code Editor Dashboard</h1>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <CodeEditor
          language="html"
          label="HTML"
          value={html}
          onChange={setHtml}
        />
        <CodeEditor language="css" label="CSS" value={css} onChange={setCss} />
        <CodeEditor
          language="javascript"
          label="JavaScript"
          value={js}
          onChange={setJs}
        />
      </div>
      <button
        onClick={runCode}
        style={{ marginTop: '1rem', padding: '0.5rem' }}
      >
        Run Code
      </button>
      <iframe
        srcDoc={srcDoc}
        title="Output"
        style={{ width: '100%', height: '300px', border: '1px solid #ccc' }}
      />
    </div>
  );
}

export default CodeEditorDashboard;
