// services/chatbotService.js
import axiosInstance from './axiosInstance';

const API_BASE_URL = '/api/chatbot-settings';

const getChatbotsByUserId = async () => {
  console.log('Fetching chatbots for the authenticated user.');
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/user`);
    console.log('Successfully fetched chatbots:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching chatbots:', error);
    throw error;
  }
};

const getChatbotById = async chatbotId => {
  console.log(`Fetching chatbot details for ID: ${chatbotId}`);
  try {
    const response = await axiosInstance.get(
      `${API_BASE_URL}/chatbot/${chatbotId}`
    );
    console.log('Successfully fetched chatbot details:', response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching chatbot with ID ${chatbotId}:`, error);
    throw error;
  }
};

const createChatbot = async chatbotData => {
  console.log('Creating a new chatbot with data:', chatbotData);
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/save`,
      chatbotData
    );
    console.log('Successfully created a new chatbot:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating chatbot:', error);
    throw error;
  }
};

const updateChatbot = async (chatbotId, chatbotData) => {
  console.log(`Updating chatbot with ID: ${chatbotId} with data:`, chatbotData);
  try {
    const response = await axiosInstance.patch(
      `${API_BASE_URL}/chatbot/${chatbotId}`,
      chatbotData
    );
    console.log('Successfully updated chatbot:', response.data);
    return response.data;
  } catch (error) {
    console.error(`Error updating chatbot with ID ${chatbotId}:`, error);
    throw error;
  }
};

const deleteChatbot = async chatbotId => {
  console.log(`Deleting chatbot with ID: ${chatbotId}`);
  try {
    const response = await axiosInstance.delete(
      `${API_BASE_URL}/chatbot/${chatbotId}`
    );
    console.log('Successfully deleted chatbot:', response.data);
    return response.data;
  } catch (error) {
    console.error(`Error deleting chatbot with ID ${chatbotId}:`, error);
    throw error;
  }
};

// New Service for Chatbot Usage
const getChatbotUsage = async (chatbotId, startDate, endDate) => {
  console.log(
    `Fetching usage data for chatbot ID: ${chatbotId} from ${startDate} to ${endDate}`
  );
  try {
    const response = await axiosInstance.get(
      `${API_BASE_URL}/chatbot/${chatbotId}/usage`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    console.log('Successfully fetched chatbot usage data:', response.data);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching usage data for chatbot with ID ${chatbotId}:`,
      error
    );
    throw error;
  }
};

const getTotalUsageByUser = async (startDate, endDate) => {
  console.log(
    `Fetching total usage data for the user from ${startDate} to ${endDate}`
  );
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/total-usage`, {
      params: {
        startDate,
        endDate,
      },
    });
    console.log('Successfully fetched total usage data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching total usage data for user:', error);
    throw error;
  }
};

export default {
  getChatbotsByUserId,
  getChatbotById,
  createChatbot,
  updateChatbot,
  deleteChatbot,
  getChatbotUsage,
  getTotalUsageByUser,
};
