import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importiere die Übersetzungsdateien
import translationDE from './locales/translation_DE.json';
import translationEN from './locales/translation_EN.json';

i18n
  .use(initReactI18next) // Bind i18n an react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
    },
    lng: 'de', // Standard-Sprache
    fallbackLng: 'en', // Fallback-Sprache
    interpolation: {
      escapeValue: false, // React behandelt XSS-Schutz
    },
  });

export default i18n;
