// services/userService.js
import axiosInstance from './axiosInstance';

const API_BASE_URL = '/api/user';

const getUserProfile = async () => {
  console.log('Fetching user profile data.');
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/profile`, {
      withCredentials: true,
    });
    console.log('Successfully fetched user profile:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

const updateUserProfile = async userData => {
  console.log('Updating user profile with data:', userData);
  try {
    const response = await axiosInstance.patch(
      `${API_BASE_URL}/profile`,
      userData,
      { withCredentials: true }
    );
    console.log('Successfully updated user profile:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

const getUserStatistics = async (userId, startDate, endDate) => {
  console.log(
    `Fetching user statistics for user ID: ${userId} from ${startDate} to ${endDate}`
  );
  try {
    const response = await axiosInstance.get(
      `${API_BASE_URL}/${userId}/statistics`,
      {
        params: {
          startDate,
          endDate,
        },
        withCredentials: true,
      }
    );
    console.log('Successfully fetched user statistics:', response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching statistics for user ID ${userId}:`, error);
    throw error;
  }
};

export default {
  getUserProfile,
  updateUserProfile,
  getUserStatistics,
};
