import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRoleManagementComponent from './components/AdminRoleManagementComponent';
import AdminSystemSettingsComponent from './components/AdminSystemSettingsComponent';
import AdminUserManagementPage from './components/AdminUserManagementPage';
import ChatbotStatisticsComponent from './components/ChatbotStatisticsComponent';
import CodeEditorDashboard from './components/codeeditor/CodeEditorDashboard';
import UserProfileComponent from './components/UserProfileComponent';
import FaqManagement from './FaqManagement';
import ForgotPassword from './ForgotPassword';
import useTokenRefresh from './hooks/useTokenRefresh';
import Login from './Login';
import AdminDashboardPage from './pages/AdminDashboardPage';
import ChatbotSettingsPage from './pages/ChatbotSettingsPage';
import DashboardPage from './pages/DashboardPage';
import ResetPassword from './ResetPassword';
import Signup from './Signup';

function PrivateRoute({ element, isAuthenticated }) {
  return isAuthenticated ? element : <Navigate replace to="/login" />;
}

function AdminRoute({ element, isAuthenticated, userRole }) {
  return isAuthenticated && userRole === 'admin' ? element : <Navigate replace to="/login" />;
}

function AppRoutes() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const userRole = useSelector(state => state.user.role); 

  // Use token refresh mechanism
  useTokenRefresh();

  useEffect(() => {
    if (!isAuthenticated) {
      console.warn('User is not authenticated, redirecting to login.');
    }
  }, [isAuthenticated]);

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      {/* ✅ Reset Password should always be accessible */}
      <Route path="/reset-password" element={<ResetPassword />} />

      {/* Protected User Routes */}
      <Route path="/pages/code-editor" element={<PrivateRoute element={<CodeEditorDashboard />} isAuthenticated={isAuthenticated} />} />
      <Route path="/faq-management" element={<PrivateRoute element={<FaqManagement />} isAuthenticated={isAuthenticated} />} />
      <Route path="/pages/dashboard" element={<PrivateRoute element={<DashboardPage />} isAuthenticated={isAuthenticated} />} />
      <Route path="/chatbot-settings" element={<PrivateRoute element={<ChatbotSettingsPage />} isAuthenticated={isAuthenticated} />} />
      <Route path="/user-profile" element={<PrivateRoute element={<UserProfileComponent />} isAuthenticated={isAuthenticated} />} />
      <Route path="/statistics" element={<PrivateRoute element={<ChatbotStatisticsComponent />} isAuthenticated={isAuthenticated} />} />

      {/* Admin Routes */}
      <Route path="/admin-dashboard" element={<AdminRoute element={<AdminDashboardPage />} isAuthenticated={isAuthenticated} userRole={userRole} />} />
      <Route path="/user-management" element={<AdminRoute element={<AdminUserManagementPage />} isAuthenticated={isAuthenticated} userRole={userRole} />} />
      <Route path="/role-management" element={<AdminRoute element={<AdminRoleManagementComponent />} isAuthenticated={isAuthenticated} userRole={userRole} />} />
      <Route path="/system-settings" element={<AdminRoute element={<AdminSystemSettingsComponent />} isAuthenticated={isAuthenticated} userRole={userRole} />} />

      {/* Catch-All Route */}
      <Route path="*" element={isAuthenticated ? <Navigate to="/pages/dashboard" /> : <Navigate to="/login" />} />
    </Routes>
  );
}

export default AppRoutes;
