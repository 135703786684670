import './App.css';
import AppRoutes from './AppRoutes'; // Importiere die Routing-Komponente

function App() {
  return (
    <div className="app-container">
      <AppRoutes />
    </div>
  );
}

export default App;
